import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import './App.css';
import Cookies from 'js-cookie';
import { useSwipeable } from 'react-swipeable';  // Use the useSwipeable hook

const socket = io('https://vokatinder.be');

const App = () => {
    const [profiles, setProfiles] = useState([]);
    const [matches, setMatches] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(Cookies.get('currentUserId') || null);
    const [currentUserName, setCurrentUserName] = useState(Cookies.get('currentUserName') || '');
    const [swipedProfiles, setSwipedProfiles] = useState(new Set(JSON.parse(Cookies.get('swipedProfiles') || '[]')));
    const [swipeClass, setSwipeClass] = useState('');

    const [form, setForm] = useState({
        name: '',
        company: '',
        strengths: ['', '', ''],
        challenges: ['', '', '']
    });

    useEffect(() => {
        socket.on('match', () => {
            alert('Gematched');
            fetchMatches();
        });

        socket.on('updateProfiles', (updatedProfiles) => {
            setProfiles(updatedProfiles);
        });

        const fetchProfiles = async () => {
            try {
                const response = await axios.get('https://vokatinder.be/api/profiles');
                setProfiles(response.data);
            } catch (error) {
                console.error('There was an error fetching the profiles!', error);
            }
        };

        const fetchMatches = async () => {
            try {
                const response = await axios.get('https://vokatinder.be/api/matches');
                setMatches(response.data);
            } catch (error) {
                console.error('There was an error fetching the matches!', error);
            }
        };

        fetchProfiles();
        fetchMatches();

        return () => {
            socket.off('match');
            socket.off('updateProfiles');
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const [field, index] = name.split('-');

        if (field === 'strengths' || field === 'challenges') {
            setForm({
                ...form,
                [field]: form[field].map((item, i) => (i === parseInt(index) ? value : item))
            });
        } else {
            setForm({
                ...form,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://vokatinder.be/api/register', form);
            setCurrentUserId(response.data._id);
            Cookies.set('currentUserId', response.data._id);
            Cookies.set('currentUserName', response.data.name);
            setCurrentUserName(response.data.name);
            setForm({
                name: '',
                company: '',
                strengths: ['', '', ''],
                challenges: ['', '', '']
            });
            const updatedProfiles = await axios.get('https://vokatinder.be/api/profiles');
            setProfiles(updatedProfiles.data);
        } catch (error) {
            console.error('There was an error registering the user!', error);
        }
    };

    const handleSwipe = async (profile, direction) => {
        try {
            setSwipeClass(`swiped-${direction}`);
            await axios.post('https://vokatinder.be/api/swipe', {
                userId: currentUserId,
                targetUserId: profile._id,
                direction
            });
            setSwipedProfiles((prevSet) => {
                const updatedSet = new Set(prevSet).add(profile._id);
                Cookies.set('swipedProfiles', JSON.stringify(Array.from(updatedSet)));
                return updatedSet;
            });
            setTimeout(() => setSwipeClass(''), 300); // Reset the class after animation
        } catch (error) {
            console.error('There was an error swiping the profile!', error);
        }
    };

    const availableProfiles = profiles.filter(profile => profile._id !== currentUserId && !swipedProfiles.has(profile._id));
    const currentProfile = availableProfiles[0];

    const totalMatches = matches.filter(match => match.user1 === currentUserId || match.user2 === currentUserId).length;

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => handleSwipe(currentProfile, 'left'),
        onSwipedRight: () => handleSwipe(currentProfile, 'right'),
        trackMouse: true, // Optional: Allows swipe with mouse for testing on desktop
    });

    return (
        <div className="App">
            <header className="App-header">
                <img src="/voka-logo.svg" className="App-logo" alt="logo" />
                <h1>Business Tinder</h1>
                {currentUserId && <p>Welkom {currentUserName}</p>}
                {currentUserId && <p className="matches-count">Aantal Matches: {totalMatches}</p>}
            </header>
            <main className="App-main">
                {!currentUserId && (
                    <div className="App-form">
                        <p className="App-subtitle">Met Tinder for Business introduceren we een nieuwe manier om mensen te laten netwerken. Net als bij Tinder swipe je profielen naar rechts als je ze interessant vindt en naar links als het niks voor jou is. Dit is echter geen vleeskeuring, je beoordeelt de ander op basis van expertises en uitdagingen die hij/zij heeft. Net zoals in onze lerende netwerken, leer je het meest van elkaar door anderen te ontmoeten met kennis en ervaringen die je nog niet hebt of al hebt meegemaakt.
                                                   <br /> Let op: na het registreren kan je jouw profiel niet meer aanpassen!</p>
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="name" placeholder="Naam" value={form.name} onChange={handleChange} required />
                            <input type="text" name="company" placeholder="Bedrijf" value={form.company} onChange={handleChange} required />
                            <div className="multi-input">
                                <label>Expertises</label>
                                {form.strengths.map((strength, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        name={`strengths-${index}`}
                                        placeholder={`Expertise ${index + 1}`}
                                        value={strength}
                                        onChange={handleChange}
                                    />
                                ))}
                            </div>
                            <div className="multi-input">
                                <label>Uitdagingen</label>
                                {form.challenges.map((challenge, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        name={`challenges-${index}`}
                                        placeholder={`Uitdaging ${index + 1}`}
                                        value={challenge}
                                        onChange={handleChange}
                                    />
                                ))}
                            </div>
                            <button type="submit">Register</button>
                        </form>
                    </div>
                )}
                {currentUserId && currentProfile && (
                    <div {...swipeHandlers} className={`swipe-container ${swipeClass}`}>
                        <div className="profile">
                            <p>Expertises: {Array.isArray(currentProfile.strengths) ? currentProfile.strengths.join(', ') : currentProfile.strengths}</p>
                            <p>Uitdagingen: {Array.isArray(currentProfile.challenges) ? currentProfile.challenges.join(', ') : currentProfile.challenges}</p>
                            <div className="swipe-buttons">
                                <button onClick={() => handleSwipe(currentProfile, 'left')} className="swipe-left">
                                    <i className="fas fa-times-circle" style={{ color: 'red', fontSize: '50px' }}></i>
                                </button>
                                <button onClick={() => handleSwipe(currentProfile, 'right')} className="swipe-right">
                                    <i className="fas fa-check-circle" style={{ color: 'green', fontSize: '50px' }}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {currentUserId && !currentProfile && (
                    <p>Geen profielen over</p>
                )}
            </main>
        </div>
    );
};

export default App;